"use client";

import { useEffect } from "react";
import Block from "../icons/Block";
import Gem from "../icons/Gem";
import Ticket from "../icons/Ticket";
import { getRequest } from "../../utils/requests";
import { ErrorResponseData, PointsBalanceResponse, TicketBalanceResponse } from "../../types/requestTypes";
import useStore from "../../lib/store";
import { NAVIGATION_TABS } from "../../types/home";
import { ACTIVITY_TYPES } from "../../types/activity";

const UserBalance = () => {
  const gemCount = useStore(state => state.gemCount);
  const setGemCount = useStore(state => state.setGemCount);
  const ticketCount = useStore(state => state.ticketCount);
  const setTicketCount = useStore(state => state.setTicketCount);
  const setActiveTab = useStore(state => state.setActiveTab);
  const setActiveActivityTab = useStore(state => state.setActiveActivityTab);

  const handleGemClick = () => {
    setActiveTab(NAVIGATION_TABS.ACTIVITY_LOG);
    setActiveActivityTab(ACTIVITY_TYPES.GEMS);
  };
  const handleTicketClick = () => {
    setActiveTab(NAVIGATION_TABS.ACTIVITY_LOG);
    setActiveActivityTab(ACTIVITY_TYPES.TICKETS);
  };

  const fetchGemCount = async () => {
    const response = await getRequest("miniapp/points_balance");
    handleReponse(response);
  };

  const fetchTicketCount = async () => {
    const response = await getRequest("miniapp/game/tickets");
    handleReponse(response);
  };

  const handleReponse = (
    response: ErrorResponseData | TicketBalanceResponse | PointsBalanceResponse
  ) => {
    const { status, data } = response;
    if (status === 200) {
      if (data && "game_points" in data) {
        setGemCount(data.game_points);
      } else if (data && "game_tickets" in data) {
        setTicketCount(data.game_tickets);
      }
    }
  };

  useEffect(() => {
    fetchGemCount();
    fetchTicketCount();
  }, []);

  return (
    <div className="flex items-center gap-2 mr-1 h-[50px]">
      <button className="relative inline-block" onClick={handleGemClick}>
        <Block
          width={78}
          height={36}
          backgroundColorLeftTop="#FFE4F3"
          backgroundColorRightBottom="#EC57A7"
          rightBottomCornerColor="#C4257B"
          rightTopCornerColor="#FFB1DB"
          leftBottomCornerColor="#FFB1DB"
          leftTopCornerColor="white"
          primaryColor="#FF90CC"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[78px]">
          <div className="flex items-center justify-center gap-2 w-full">
            <Gem />
            <div>{gemCount}</div>
          </div>
        </div>
      </button>
      <button className="relative inline-block" onClick={handleTicketClick}>
        <Block
          backgroundColorLeftTop="#D8FFF8"
          backgroundColorRightBottom="#25C4A7"
          rightBottomCornerColor="#0B9279"
          rightTopCornerColor="#ABFFF0"
          leftBottomCornerColor="#ABFFF0"
          leftTopCornerColor="white"
          primaryColor="#79EED9"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[78px]">
          <div className="flex items-center justify-center gap-2 w-full">
            <Ticket />
            <div>{ticketCount}</div>
          </div>
        </div>
      </button>
    </div>
  );
};

export default UserBalance;
