import { useEffect, useMemo, useState } from "react";
import { GAME_SCREENS, GAMES } from "../../types/home";
import useStore from "../../lib/store";
import { getRequest } from "../../utils/requests";
import GameTitle from "./GameTitle";
import GameRank from "./GameRank";
import PlayerIcon from "./PlayerIcon";
import NavigationButtons from "./NavigationButtons";
import DailyRewardTimer from "../DailyReward/DailyRewardTimer";
import { getUserRank } from "../../utils/play";
import { UserStat } from "../../types/user";
import backgroundImage from "./Background";
import Header from "../Header";

const defaultStats: UserStat[] = [
  { username: "", rank: null, score: 0, game: GAMES.ICE_SNAKE },
  { username: "", rank: null, score: 0, game: GAMES.GLOVE_HERO },
  { username: "", rank: null, score: 0, game: null }
];

const Play = () => {
  const [gameScreen, setGameScreen] = useState<GAME_SCREENS>(GAME_SCREENS.GLOVE_HERO);
  const [userStats, setUserStats] = useState(defaultStats);
  const ticketCount = useStore(state => state.ticketCount);
  const nextDailyReward = useStore(state => state.nextDailyReward);
  
  const gameScreens = Object.values(GAME_SCREENS);
  const currentIndex = useMemo(() => gameScreens.indexOf(gameScreen), [gameScreen, gameScreens]);
  const playerOverallRank = useMemo(() => getUserRank(userStats, null), [userStats]);

  const scrollLeft = () => setGameScreen(gameScreens[(currentIndex - 1 + gameScreens.length) % gameScreens.length] as GAME_SCREENS);
  const scrollRight = () => setGameScreen(gameScreens[(currentIndex + 1) % gameScreens.length] as GAME_SCREENS);

  const getPlayButtonFunction = () => {
    const game = gameScreen === GAME_SCREENS.GLOVE_HERO ? "glove-hero" : "ice-snake";
    return () => (window.location.href = `games/?game=${game}`);
  };

  const fetchUserStatus = async () => {
    const { status, data } = await getRequest("miniapp/user_stats");
    if (status === 200 && data) setUserStats(data);
  };

  useEffect(() => {
    fetchUserStatus();
  }, []);

  return (
    <section className="h-[calc(100vh-79px)] flex flex-col justify-between">
      <div className="flex justify-between absolute top-4 left-0 right-0 px-2 pt-2 w-full z-10">
        <Header includeUserProfile playerOverallRank={playerOverallRank} /> 
        {nextDailyReward && (
            <div className="fixed top-1/3 right-8 transform -translate-y-1/2">
              <div className="flex gap-1 text-xs items-center">
                <DailyRewardTimer targetTimestamp={nextDailyReward} />
              </div>
          </div>
        )}
      </div>
      <div className="relative w-full h-full overflow-hidden">
        <div className="relative w-full h-full flex transition-transform duration-1000 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {gameScreens.map((screen, index) => (
            <div key={index} className="relative w-full h-full inset-0 flex-shrink-0">
              <div
                className="bg-top bg-no-repeat bg-cover bg-fixed absolute inset-0 h-[calc(100vh-79px)] flex flex-col justify-between"
                style={{ backgroundImage: `url('${backgroundImage[screen]}?w=720')`
              }}>
                <div className="opacity-80 bg-gradient-to-b from-black to-transparent mix-blend-multiply w-full h-[284px] absolute top-0 left-0" />
                  <div className={"flex items-center absolute justify-center top-1/4 w-full left-0 right-0 transform -translate-y-2/3"}>
                    <GameTitle gameScreen={screen} />
                  </div>
                  <div className="absolute top-1/2 w-full">
                    <GameRank userStats={userStats} gameScreen={screen} />
                  </div>
                  <div className="flex flex-col items-center absolute inset-0 top-1/2">
                    <PlayerIcon gameScreen={screen} />
                  </div>
                </div>
            </div>
          ))}
        </div>
        <div className="absolute bottom-0 left-0 w-full z-10">
          <NavigationButtons
            gameScreen={gameScreen}
            gameScreens={gameScreens}
            scrollLeft={scrollLeft}
            scrollRight={scrollRight}
            getPlayButtonFunction={getPlayButtonFunction}
            ticketCount={ticketCount}
          />
        </div>
      </div>
      
    </section>
  );
};

export default Play;