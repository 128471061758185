"use client";

import { useEffect, useMemo, useState } from "react";
import EarnTaskList from "./EarnTaskList";
import EarnTabs from "./EarnTabs";
import { getRequest } from "../../utils/requests";
import { CustomTask } from "../../types/earn";
import useStore from "../../lib/store";
import Header from "../Header";

const Earn = () => {
  const activeEarnTab = useStore((state) => state.activeEarnTab);
  const [customTasks, setCustomTasks] = useState<CustomTask[]>([]);

  const getTasks = async () => {
    const { data } = await getRequest("miniapp/tasks");
    if (data) {
      setCustomTasks(data?.tasks || []);
    }
  };

  const tasks = useMemo(() => {
    return customTasks.filter((task) => task.reward_type === activeEarnTab);
  }, [customTasks, activeEarnTab]);

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <div className="flex flex-col pt-6 px-2 bg-[url('/image/teal-background.png')] bg-top bg-fixed bg-[length:100%_auto] min-h-[630px] pb-[79px]">
      <Header text="How to Earn" />
      <EarnTabs />
      <EarnTaskList tasks={tasks}/>
    </div>
  );
};

export default Earn;
