"use client";

import UserProfile from "../Play/UserProfile";
import UserBalance from "../UserBalance";

interface HeaderProps {
  text?: string;
  includeUserProfile?: boolean;
  playerOverallRank?: string;
}

const Header = ({ text = "", includeUserProfile = false, playerOverallRank = "" }: HeaderProps) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex">
        <div className="flex gap-2 items-center h-[24px]">
          {
            includeUserProfile && playerOverallRank && (
              <UserProfile playerOverallRank={playerOverallRank} />
            )
          }
          {
            !includeUserProfile && text && (
              <h1 className="text-secondaryWhite text-2xl not-italic font-normal leading-normal bg-transparent px-2 h-full flex items-center">
            {text}
          </h1>
            )
          }
        </div>
      </div>
      <UserBalance />
    </div>
  );
};

export default Header;