"use-client";

import { useEffect, useState } from "react";
import Gem from "../icons/Gem";
import PokePointer from "../icons/PokePointer";
import Box from "../Box";
import { FRIEND_ACTIVITIES, FriendActivity } from "../../types/activity";
import Button from "../Form/Button";
import Checkmark from "../icons/Checkmark";
import { postRequest } from "../../utils/requests";
import Alert from "../Alert";

const FriendsActivityList = ({
  friendsActivities,
}: {
  friendsActivities: FriendActivity[];
}) => {
  const [justPokedMap, setJustPokedMap] = useState<{ [key: number]: boolean }>({});
  const [previouslyPokedMap, setPreviouslyPokedMap] = useState<{ [key: number]: boolean }>({}); // 

const [showTempPokeAlert, setShowTempPokeAlert] = useState<{ [key: number]: boolean }>({});
const [clickedReferralUserId, setClickedReferralUserId] = useState<number | null>(null);

useEffect(() => {
  const stored = localStorage.getItem("previouslyPokedMap");
  if (stored) {
    const parsed = JSON.parse(stored);
    const normalized: Record<number, boolean> = {};

    Object.entries(parsed).forEach(([key, value]) => {
      normalized[Number(key)] = value as boolean;
    });

    setPreviouslyPokedMap(normalized);
  }
}, []);

  const handlePoke = async ({ referralUserId, reason }: { referralUserId: number, reason: FRIEND_ACTIVITIES }) => {

    try {
      const poke = await postRequest("miniapp/poke", { referral_user_id: referralUserId, reason: reason.toLowerCase() });
      setClickedReferralUserId(referralUserId);
      if (poke.data.success) {
        setJustPokedMap((prev) => ({ ...prev, [referralUserId]: true }));
        setPreviouslyPokedMap((prev) => {
          const updated = { ...prev, [referralUserId]: true };
          localStorage.setItem("previouslyPokedMap", JSON.stringify(updated));
          return updated;
        });
      } else if (poke.data.message === "Poke already sent") {
        setPreviouslyPokedMap((prev) => {
          const updated = { ...prev, [referralUserId]: true };
          localStorage.setItem("previouslyPokedMap", JSON.stringify(updated));
          return updated;
        });
        setShowTempPokeAlert((prev) => ({ ...prev, [referralUserId]: true }));
      } else {
        console.error("Error poking user:", referralUserId, ". Error:", poke.data.message);
      }
    } catch (error) {
      console.error("Error poking user:", referralUserId, ". Error:", error);
    }
  };

    useEffect(() => {
      if (showTempPokeAlert && clickedReferralUserId ) {
        const timeout = setTimeout(() => {
          setShowTempPokeAlert((prev) => ({ ...prev, [clickedReferralUserId]: false }));
        }, 2000);
        return () => clearTimeout(timeout);
      }
    }, [showTempPokeAlert, clickedReferralUserId]);

  const getBoxContent = (friend: FriendActivity) => {
    const {
      username,
      user_id: referralUserId,
      gems_earned,
      last_game_played,
      verified,
    } = friend;
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    const lastTokenAwardedFormatted =
      last_game_played ? new Date(last_game_played) : "";
    const activityName = () => {
      if (
        lastTokenAwardedFormatted &&
        lastTokenAwardedFormatted < twoWeeksAgo &&
        verified
      ) {
        return FRIEND_ACTIVITIES.INACTIVE;
      } else if (gems_earned) {
        return FRIEND_ACTIVITIES.GEMS_EARNED;
      } else if (verified) {
        return FRIEND_ACTIVITIES.VERIFIED;
      } else {
        return FRIEND_ACTIVITIES.JOINED;
      }
    };

    const getTextColorForActivity = (activity: FRIEND_ACTIVITIES) => {
      switch (activity) {
        case FRIEND_ACTIVITIES.VERIFIED:
          return "text-green";
        case FRIEND_ACTIVITIES.GEMS_EARNED:
          return "text-purple";
        default:
          return "text-darkGrey";
      }
    };

    const activityImage = (activity: FRIEND_ACTIVITIES) => {
      if (activity === FRIEND_ACTIVITIES.GEMS_EARNED) {
        return (
          <>
            {" "}
            +<Gem /> {gems_earned}
          </>
        );
      } else {
        if (justPokedMap[referralUserId]) {
          return (
            <>
              <PokePointer /> POKED
            </>
          );
        }
        return (
          <>
             {showTempPokeAlert[referralUserId] && (
        <div className="absolute z-10" style={{ bottom: "58px", right: "25px" }}>
          <Alert message={"Already poked!"} icon={ <PokePointer /> } />
        </div>
      )}
          <Button
            text="POKE"
            textSize="md"
            width={88}
            height={36}
            className="text-nowrap"
            onClick={() => handlePoke({ referralUserId, reason: activity })}
            datadogActionName={`Poke [${activityName()}]`}
            disabled={previouslyPokedMap[referralUserId] || justPokedMap[referralUserId]}
            buttonColor={previouslyPokedMap[referralUserId] || justPokedMap[referralUserId] ? "grey" : "yellow"}
          />
          </>
        );
      }
    };
    const activity = activityName();

    return (
      <div className="flex py-[2px] px-3 flex justify-between items-center bg-secondaryWhite">
        <div className="flex flex-col">
          <div>{username}</div>
          <div className={`text-sm ${getTextColorForActivity(activity)}`}>
            {activity === FRIEND_ACTIVITIES.VERIFIED ? (
              <div className="flex flex-row text-green items-center">
                <Checkmark fill="#00C274" height="10" />
                {FRIEND_ACTIVITIES.VERIFIED}
              </div>
            ) : (
              activity
            )}
          </div>
        </div>
        <div className="flex flex-row gap-1 items-center">
          {activityImage(activity)}
        </div>
      </div>
    );
  };

  const renderFriendsActivity = () => {
    return friendsActivities.map((friend) => {
      return (
          <div
            key={friend.username}
            className="relative inline-block justify-center mt-2"
          >
            <Box
              width={362}
              bottomBorderColor="black"
              content={getBoxContent(friend)}
            />
          </div>
      );
    });
  };

  return <div className={"flex flex-col"}>{renderFriendsActivity()}</div>;
};

export default FriendsActivityList;
