"use client";

import { openMiniappLink } from "../../utils/utils";
import Button from "../Form/Button";
import IconDiscord from "../icons/IconDiscord";
import IconX from "../icons/IconX";

const AccountFooter = () => {
  return (
    <div className="w-full">
      <div className="flex justify-center items-center gap-[8px]">
        <button onClick={() => openMiniappLink("https://x.com/scortoken") }><IconX /></button>
        <button onClick={() => openMiniappLink("https://discord.gg/scortoken")}><IconDiscord /></button>
        <Button
          width={112}
          height={42}
          onClick={() => openMiniappLink("https://scor.io")}
          text="ABOUT $SCOR"
          fontSize="14px"
        />
        <Button
          width={56}
          height={42}
          onClick={() => openMiniappLink("https://scor.io/help")}
          text="?"
          fontSize="14px"
        />
      </div>
      <div className="flex justify-between items-center text-[14px] py-[12px] text-white px-[16px]">
        <button onClick={() => openMiniappLink("https://scor.io/whitepaper") }>$SCOR WHITEPAPER</button>
        <button onClick={() => openMiniappLink("https://about.sweet.io/legal/mini-app-privacy/") }>PRIVACY POLICY</button>
        <button onClick={() => openMiniappLink("https://about.sweet.io/legal/mini-app-terms/") }>TERMS OF USE</button>
      </div>
    </div>
  );
};

export default AccountFooter;
